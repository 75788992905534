#underlay {
    padding: 20px 20px 20px 20px;
    background: rgba(0, 0, 0, 0.7);
    width: 540px;
    height: 60px;
    border-radius: 40px;
}
#graduation {
    position: absolute;
    top: 0;
    left: 20px;
    pointer-events: none;
    width: calc(100% - 40px);
    height: 60px;
    overflow: hidden;
    #graduation_inner {
        position: absolute;
        top: 22px;
        width: 100%;
        .b, .s {
            float: left;
            position: relative;
            width: 1px;
            background: rgba(@lightBlue, 0.4);
            margin-right: 9px;

            &.l {
                margin-right: 0px;
            }
        }
        .b {
            background: rgba(@lightBlue, 1) !important;
            height: 16px;
            &:before {
                content: "";
                position: absolute;
                top: -17px;
                height: 50px;
                width: 1px;
                background-image: linear-gradient(to bottom, rgba(@lightBlue, 0.4) 30%, rgba(255, 255, 255, 0) 0%);
                background-position: left;
                background-size: 1px 5px;
                background-repeat: repeat-y;
            }
        }
        .s {
            margin-top: 3px;
            height: 10px;
        }
    }
}
#timeSlider {
    position: absolute;
    width: 540px;
    margin-left: auto;
    bottom: 10px;
    left: calc(50% - 270px);
}
#timeSlider #timeValue{
    width: 100%;
    position: absolute;
    top: -30px;
    #timeValue_inner {
        width: 100px;
        height: 30px;
        line-height: 28px;
        margin: 0 auto;
        /*border: 1px solid @blue;*/
        font-size: 0.8em;
        text-align: center;
        text-shadow: 0px 0px 10px @green;
        cursor: pointer;
        position: relative;
        strong {
            color: @green;
        }
        &.negative {
            strong {
                color: @red;
            }
        }
    }
}
input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: none;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    box-shadow: none;
    background: rgba(@timeSlider, @timeSliderOpacity);
    border-radius: 20px;
    border: none;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
  border: none;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  background: @green;
  box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@green, 0.4);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 6px;
}
input.negative[type=range]::-webkit-slider-thumb {
    background: @red !important;
    box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@red, 0.4) !important;
}
input.negative[type=range]::-moz-range-thumb {
    background: @red !important;
    box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@red, 0.4) !important;
}
input.negative[type=range]::-ms-thumb {
    background: @red !important;
    box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@red, 0.4) !important;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgba(@timeSlider, @timeSliderOpacity);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  box-shadow: none;
  background: rgba(@timeSlider, @timeSliderOpacity);
  border-radius: 20px;
  border: none;
}
input[type=range]::-moz-range-thumb {
  box-shadow: none;
  border: none;
  height: 8px;
  width: 8px;
  border-radius: 30px;
  background: rgba(@green);
  box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@green, 0.4);
  cursor: pointer;
}
input[type=range]::-moz-focus-outer {
    border: none;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: rgba(@timeSlider, @timeSliderOpacity);
  border: none;
  border-radius: 20px;
  box-shadow: none;
}
input[type=range]::-ms-fill-upper {
  background: rgba(@timeSlider, @timeSliderOpacity);
  border: none;
  border-radius: 20px;
  box-shadow: none;
}
input[type=range]::-ms-thumb {
  box-shadow: none;
  border: none;
  width: 8px;
  height: 8px;
  border-radius: 30px;
  background: @green, 0.6;
  box-shadow: 0 0 0 4px #2f3f3e,
    0 0 0 12px rgba(@green, 0.4);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: rgba(@timeSlider, @timeSliderOpacity);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgba(@timeSlider, @timeSliderOpacity);
}

@media screen and (max-width:600px) {
    #timeSlider {
        position: fixed;
        bottom: 10px;
        width: calc(100% - 20px);
        left: 10px;
    }

    #underlay {
        width: 100%;
    }

    #timeSlider #timeValue{
        width: 100px;
        margin-left: calc(50% - 50px);
    }
}
