#compass {
    display: block;
    position: absolute;
    top: 17px;
    left: 70px;

    path {
        transform-origin: center;
        transform: "rotate(-18deg)";
    }
}
