#openSatList {
    display: none;
    padding: 8px 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.7);

    &:hover {
        background: rgba(@blue, 0.3);
    }

    &.show {
        display: block;
    }

    .inner {
        border-top: 1px solid @blue;
        border-bottom: 1px solid @blue;
        width: 30px;
        height: 34px;
        transform: rotate(180deg);

        &:before {
            content: "";
            display: block;
            transform:
                translateY(15px)
                translateX(13px)
                rotate(45deg);
            width: 15px;
            height: 2px;
            position: absolute;
            background: @lightBlue;
            box-shadow: 0px 0px 5px @lightBlue;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            transform:
                translateY(15px)
                translateX(3px)
                rotate(-45deg);
            width: 15px;
            height: 2px;
            background: @lightBlue;
            box-shadow: 0px 0px 5px @lightBlue;
        }
    }

    &.opened {
        .inner {
            transform: rotate(0deg);
        }
    }
}

#satList {
    position: absolute;
    padding: 10px;
    top: 100px;
    right: 10px;
    width: 200px;
    max-height: 80vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);

    transform-origin: top right;
    transition: transform 0.2s;
    transform: scale(0, 0);

    #satList_inner {
        transition-delay: 0.2s;
        transition-property: opacity;
        transition-duration: 0.1s;
        opacity: 0;
        position: relative;
        border-top: 1px solid @blue;
        border-bottom: 1px solid @blue;
    }

    &.animation {
        transform: scale(1, 1);
        #satList_inner {
            opacity: 1;
        }
    }

    #satList_count {
        padding: 5px 0 0 0;
        font-size: 9px;
        text-align: right;
        color: @blue;

        span {
            color: @green;
            font-weight: bold;
        }
    }

    #satList_list {
        overflow-y: scroll;
        margin-top: 5px;
        max-height: calc(80vh - 84px);

        #satList_filter {
            margin: 5px 0;
        }

        sat-list-constellation {
            display: block;
            position: relative;
            margin-bottom: 5px;
            .constellation-infos, .close {
                display: inline-block;
                position: absolute;
            }
            .constellation-infos {
                width: 100%;
                height: 100%;
                transition: width 0.2s;
                cursor: pointer;
                border: 1px solid @blue;
                line-height: 38px;
                text-transform: uppercase;
                text-align: center;
                position: relative;
                word-wrap: break-word;
                hyphens: auto;
                color: @blue;
                small {
                    color: @green;
                    font-size: 8px;
                }
                .count {
                    position: absolute;
                    bottom: 1px;
                    right: 1px;
                    font-size: 9px;
                    line-height: 9px;
                    text-align: right;
                    color: @blue;
                    font-weight: bold;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 5px;
                    background: @blue;
                }

            }
            .close {
                right: 0;
                background: rgba(@blue, 0.3);
                width: 40px;
                height: 42px;
                transform-origin: top right;
                transform: scaleX(0);
                transition: transform 0.1s;
                .inner{
                    transition-delay: 0.1s;
                    transition-property: opacity;
                    transition-duration: 0.1s;
                    opacity: 0;
                    &:before {
                        display: block;
                        position: absolute;
                        left: -2px;
                        top: -2px;
                        content: "";
                        width:  35px;
                        height: 1px;
                        background: rgba(@lightBlue);
                        transform:
                            translateY(22px)
                            translateX(5px)
                            rotate(45deg);
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        left: -2px;
                        top: -2px;
                        content: "";
                        width:  35px;
                        height: 1px;
                        background: rgba(@lightBlue);
                        transform:
                            translateY(22px)
                            translateX(5px)
                            rotate(-45deg);
                    }
                }
            }
            &:hover {
                border-color: @lightBlue;
                &:before {
                    background: @lightBlue;
                }
                .close:hover {
                    background: rgba(@blue, 0.8);
                }
                .constellation-infos {
                    width: calc(100% - 40px);
                    span {
                        color: @lightBlue !important;
                    }
                }
                .close {
                    transform: scaleX(1);
                    display: inline-block;
                    .inner {
                        opacity: 1;
                    }
                }
            }
            .constellation-satellites {
                display: block;
                border-left: 3px solid rgba(@lightBlue, 0.3);
                padding: 3px 0 0 3px;
                margin-left: 5px;
            }
        }

        .uiSat {
            display: block;
            width: 100%;
            /*border: 1px solid @blue;*/
            background: rgba(11, 39, 52, 0.8);
            height: 40px;
            position: relative;
            margin-bottom: 5px;
            cursor: pointer;
            div.content, div.close {
                display: inline-block;
                position: absolute;
            }
            div.content {
                left: 0;
                width: 100%;
                height: 100%;
                transition: width 0.2s;
                padding-left: 20px;
                display: table;
                word-wrap: break-word;
                hyphens: auto;
                span {
                    text-align: center;
                    color: @blue !important;
                    display: table-cell;
                    vertical-align: middle;
                    word-wrap: anywhere;
                }
            }
            &.focused {
                border: 1px solid @green !important;
                &:before {
                    background: @lightBlue;
                }
            }
            &:before {
                content: "";
                position: absolute;
                display: block;
                height: 100%;
                width: 20px;
                background: rgba(@lightBlue, 0.3);
                transition: background 0.2s;
            }
            div.close {
                right: 0;
                background: rgba(@blue, 0.3);
                width: 40px;
                height: 40px;
                transform-origin: top right;
                transform: scaleX(0);
                transition: transform 0.1s;
                .inner{
                    transition-delay: 0.1s;
                    transition-property: opacity;
                    transition-duration: 0.1s;
                    opacity: 0;
                    &:before {
                        display: block;
                        position: absolute;
                        left: -2px;
                        top: -2px;
                        content: "";
                        width:  35px;
                        height: 1px;
                        background: rgba(@lightBlue);
                        transform:
                            translateY(20px)
                            translateX(5px)
                            rotate(45deg);
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        left: -2px;
                        top: -2px;
                        content: "";
                        width:  35px;
                        height: 1px;
                        background: rgba(@lightBlue);
                        transform:
                            translateY(20px)
                            translateX(5px)
                            rotate(-45deg);
                    }
                }
            }
            &.delete:hover {
                border-color: @lightBlue;
                &:before {
                    background: @lightBlue;
                }
                div.close:hover {
                    background: rgba(@blue, 0.8);
                }
                div.content {
                    width: calc(100% - 40px);
                    span {
                        color: @lightBlue !important;
                    }
                }
                div.close {
                    transform: scaleX(1);
                    display: inline-block;
                    .inner {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    #satList {
        transform-origin: top left;
        width: calc(100% - 70px);
        top: 10px;
        left: 10px;

        .smallBorder.top.right, .smallBorder.bottom.right {
            display: block;
        }
        .smallBorder.top.left, .smallBorder.bottom.left {
            display: none;
        }

        #satList_minus {
            /*right: 0;*/
        }

        &.expended {
            bottom: 90px;
            #satList_inner {
                height: inherit;
                max-height: 40vh;
            }

            #satList_list {
                max-height: 35vh;
                .uiSat {
                    margin-left: 0;
                }
            }
        }
    }
}
