#popUpMenu {
    position: absolute;
    padding: 10px;
    background: rgba(0, 0, 0, 0.7);
    top: 100px;
    left: 10px;
    width: 300px;
    z-index: 2;

    transform-origin: top left;
    transition: transform 0.2s;
    transform: scale(0, 0);

    #popUpMenu_inner {
        transition-delay: 0.2s;
        transition-property: opacity;
        transition-duration: 0.1s;
        opacity: 0;
        border-top: 1px solid @blue;
        position: relative;
    }

    &.animation {
        transform: scale(1, 1);
        #popUpMenu_inner {
            opacity: 1;
        }
    }

    label.radio, label.checkbox {
        width: 100px;
        margin-right: 5px;
        div {
            &:after {
                transform:
                    translateY(20px)
                    translateX(5px)
                    rotate(-21deg);
                width: 108px;
                left: -9px;
                top: 1px;
            }
        }
    }

    #popUpMenu_close {
        position: absolute;
        right: 0;
        top: 12px;
        width: 60px;
        height: 30px;
        border: 1px solid rgba(@lightBlue, 0.5);
        z-index: 1;

        div {
            position: absolute;
            right: 0;
            width: 50%;
            height: 100%;
            background: rgba(@lightBlue, 0.3);
            cursor: pointer;

            &:hover {
                background: rgba(@blue, 0.8);
            }

            &:before {
                display: block;
                position: absolute;
                left: -5px;
                top: -6px;
                content: "";
                width:  30px;
                height: 1px;
                background: rgba(@lightBlue);
                transform:
                    translateY(20px)
                    translateX(5px)
                    rotate(45deg);
            }
            &:after {
                display: block;
                position: absolute;
                left: -5px;
                top: -6px;
                content: "";
                width:  30px;
                height: 1px;
                background: rgba(@lightBlue);
                transform:
                    translateY(20px)
                    translateX(5px)
                    rotate(-45deg);
            }
        }
    }

    .section {
        position: relative;

        &:not(.noLine):after {
            content: "";
            position: absolute;
            width: 30px;
            height: 1px;
            right: 0;
            bottom: -3px;
            background: @blue;
        }
    }

    #search {
        border-bottom: 1px solid @green;
    }

    textarea {
        width: 100%;
        height: 130px;
        background: rgba(@blue, 0.1);
        border-left: 1px solid @green;
        border-right: 1px solid @green;
        border-top: 0;
        border-bottom: 0;
        padding: 0 5px;
        color: #9ef3f2;
        text-shadow: 0px 0px 10px rgba(43,208,255,0.5);
        resize: vertical;
    }

    #saveTle {
        width: 100%;
        margin-top: 10px;
    }

    #results {
        position: absolute;
        display: none;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        .result {
            cursor: pointer;
            padding: 7px 0 7px 5px;
            border-bottom: 1px solid rgba(@blue, 0.3);
            position: relative;
            p {
                color: @blue;
                text-transform: uppercase;
            }
            &:hover {
                p {
                    color: @lightBlue;
                }
                border-bottom: 1px solid rgba(@green, 0.6);
            }
        }
        &.show {
            display: block;
        }
    }

    #about {
        text-align: right;
        margin: 20px 0 0 0;
        position: relative;
        cursor: pointer;
        font-size: 9px;
    }

    .section-fpv {
        button {
            width: 100px;
            color: rgba(@blue, 0.6);
            border-color: rgba(@blue, 0.6);
            &:after {
                transform:
                    translateY(18px)
                    translateX(5px)
                    rotate(-21deg);
                width: 108px;
                left: -9px;
                top: 1px;
                content: "";
                position: absolute;
                height: 1px;
                background: rgba(@blue, 0.6);
            }
        }
    }
}

@media screen and (max-width:600px) {
    #popUpMenu {
        transform-origin: bottom right;
        top: inherit;
        bottom: 10px;
        left: 0px;
        width: 100%;
    }
}
