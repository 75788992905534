#heightScale, #zoomScale {
}

#heightScale {
    right: 250px;
}
#zoomScale {
    left: 250px;
}

.verticalScale.left {
    .vLine {
    }
    .vValue {
        left: -90px;
        &:before {
            left: 70px;
        }
    }
    .vGraduation .b-label {
        text-align: left;
        top: -5px;
        left: 15px;
        right: inherit;
    }
}

.verticalScale {
    position: absolute;
    height: 400px;
    pointer-events: none;

    top: calc(50% - 200px);

    .vLine {
        height: 100%;
        width: 1px;
        background: rgba(@blue, 0.5);
        margin-left: 5px;
    }

    .vValue {
        position: absolute;
        top: 50%;
        left: 30px;
        width: 70px;
        height: 40px;
        margin-top: -20px;
        border: 1px solid @blue;
        box-shadow: 0 0 4px rgba(@lightBlue, 0.7), inset 0 0 4px rgba(@lightBlue, 0.7);
        div {
            color: @lightBlue;
            text-shadow: 0 0 4px @lightBlue;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
        }

        &:before {
            content: "";
            position: absolute;
            box-shadow: 0 0 4px rgba(@lightBlue, 0.7), inset 0 0 4px rgba(@lightBlue, 0.7);
            top: 50%;
            left: -30px;
            width: 30px;
            height: 1px;
            background: @blue;
        }
    }

    .vGraduation {
        position: absolute;
        top: 0;
        left: 0;
        .b, .s {
            position: relative;
            display: block;
            height: 1px;
            margin-bottom: 9px;

            &.l {
                margin-bottom: 0px;
            }
        }
        .b {
            width: 11px;
            background: rgba(@lightBlue, 1);
            box-shadow: 0 0 4px @lightBlue;
        }
        .s {
            background: rgba(@blue, 0.5);
            width: 7px;
            margin-left: 2px;
        }
        .b-label {
            position: absolute;
            font-size: 9px;

            text-align: right;
            top: -5px;
            right: 15px;
        }
    }
}

@media screen and (max-width:600px) {
    #heightScale {
        right: 23px;
    }
    #zoomScale {
        left: 23px;
    }

    .verticalScale {
        .vValue {
            left: -90px;
            &:before {
                left: 70px;
            }
        }
        .vGraduation .b-label {
            text-align: left;
            left: 15px;
            right: inherit;
        }
    }


    .verticalScale.left {
        .vValue {
            left: 30px;
            &:before {
                left: -30px;
            }
        }
        .vGraduation .b-label {
            text-align: right;
            left: inherit;
            right: 15px;
        }
    }
}

