* {
    margin: 0;
    padding: 0;

    font-family: menlo,
                monospace,
                sans-serif;
    color: @lightBlue;
    text-shadow: 0px 0px 10px rgba(@lightBlue,0.5);
    box-sizing: border-box;
}

body {
    background: #181818;
    font-size: 12px;
}

h1 {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 20px 0;
}

h2 {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 20px 0;
}

input[type="text"] {
    border: none;
    color: @lightBlue;
}

button {
    background: none;
    height: 40px;
    line-height: 38px;
    text-transform: uppercase;
    text-align: center;
    color: @blue;
    border: 1px solid @blue;
    position: relative;
    cursor: pointer;

    &:hover {
        /*background: rgba(@lightBlue, 0.1);*/
        color: @lightBlue;
        border: 1px solid @lightBlue;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 5px;
        background: @blue;
    }
}

a {
    color: @green;
    text-shadow: 0px 0px 10px rgba(@green,0.5);
}

.description {
    font-size: 1.4em;
    padding: 15px 0 30px 0;
}

.row {
    width: 80%;
    margin: 0 auto;
}

.smallBorder {
    width: 30px;
    height: 1px;
    background: @blue;
    position: absolute;

    &.left {
        left: 10px;
    }
    &.right {
        right: 10px;
    }
    &.top {
        top: 7px;
    }
    &.bottom {
        bottom: 7px;
    }
}

#canvas {
    display: block;
    width: 100vw;
    height: 100vh;
}
