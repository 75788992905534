#focusedInfo {
    display: block;
    position: absolute;
    width: 540px;
    margin-left: auto;
    top: 100px;
    left: calc(50% - 270px);
    background: rgba(11, 39, 52, 0.8);
    transform: scaleY(0);
    transform-origin: top left;
    transition: transform .1s ease-out;

    .inner {
        padding: 20px 40px;
        color: @blue;
        text-shadow: 0px 0px 10px rgba(@blue,0.5);

        small {
            color: @lightBlue;
        }

        .info {
            font-size: 11px;
            color: @lightBlue;
            span {
                color: @orange;
            }

            &.first {
                padding-top: 5px;
            }
        }

        .action {
            color: @green;
            position: relative;
            text-align: right;
            display: block;
            cursor: pointer;
            font-size: 11px;
            padding-top: 5px;

            &:hover {
                color: @lightBlue;
            }

            &.first {
                padding-top: 10px;

                &:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 1px;
                    right: 0;
                    top: 5px;
                    background: @blue;
                }
            }
        }
    }

    &:before {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 20px;
        background: rgba(@lightBlue, 0.3);
    }

    &.animation {
        transform: scaleY(1);
    }

    div.close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        background: rgba(@blue, 0.3);
        width: 40px;
        height: 40px;
        .inner{
            &:before {
                display: block;
                position: absolute;
                left: -2px;
                top: -2px;
                content: "";
                width:  35px;
                height: 1px;
                background: rgba(@lightBlue);
                transform:
                    translateY(20px)
                    translateX(5px)
                    rotate(45deg);
            }
            &:after {
                display: block;
                position: absolute;
                left: -2px;
                top: -2px;
                content: "";
                width:  35px;
                height: 1px;
                background: rgba(@lightBlue);
                transform:
                    translateY(20px)
                    translateX(5px)
                    rotate(-45deg);
            }
        }
    }
}

@media screen and (max-width:600px) {
    #focusedInfo {
        width: calc(100% - 20px);
        left: 10px;
    }
}
