#tooltip {
    display: block;
    position: absolute;
    background: rgba(11, 39, 52, 0.8);
    transform: scaleY(0);
    transform-origin: top left;
    transition: transform .1s ease-out;

    #tooltip_inner {
        padding: 20px 20px 20px 40px;
        color: @blue;
        text-shadow: 0px 0px 10px rgba(@blue,0.5);

        h4 {
            font-weight: inherit;
            color: inherit;
            cursor: pointer;

            &:hover {
                color: @lightBlue;
            }
        }

        .info {
            font-size: 11px;
            color: @lightBlue;
            span {
                color: @orange;
            }

            &.first {
                padding-top: 5px;
            }
        }

        .action {
            color: @green;
            position: relative;
            text-align: right;
            display: block;
            cursor: pointer;
            font-size: 11px;
            padding-top: 5px;

            &:hover {
                color: @lightBlue;
            }

            &.first {
                padding-top: 10px;

                &:after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 1px;
                    right: 0;
                    top: 5px;
                    background: @blue;
                }
            }
        }


    }

    &:before {
        content: "";
        position: absolute;
        display: block;
        height: 100%;
        width: 20px;
        background: rgba(@lightBlue, 0.3);
    }

    &.animation {
        transform: scaleY(1);
    }
}
