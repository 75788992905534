#openMenuButton {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    padding: 8px 10px;
    background: rgba(0, 0, 0, 0.7);

    &:hover {
        background: rgba(@blue, 0.3);
    }

    #inner {
        width: 30px;
        height: 34px;
        border-top: 1px solid @blue;
        border-bottom: 1px solid @blue;
        &:before {
            content: "";
            display: block;
            transform:
                translateY(15px)
                translateX(5px);
            width: 20px;
            height: 2px;
            position: absolute;
            background: @lightBlue;
            box-shadow: 0px 0px 5px @lightBlue;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            transform:
                translateY(15px)
                translateX(5px)
                rotate(90deg);
            width: 20px;
            height: 2px;
            background: @lightBlue;
            box-shadow: 0px 0px 5px @lightBlue;
        }
    }
}

@media screen and (max-width:600px) {
    #openMenuButton {
        position: fixed;
        top: inherit;
        left: inherit;
        bottom: 80px;
        right: 10px;
    }
}
