#globalCross {
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;

    #h {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background: rgba(@lightBlue, 20%);
    }
    #v {
        position: absolute;
        left: 50%;
        width: 1px;
        height: 100%;
        background: rgba(@lightBlue, 20%);
    }

    #cch {
        position: absolute;
        top: 50%;
        left: calc(50% - 5px);
        width: 11px;
        height: 1px;
        background: @lightBlue;
        box-shadow: 0px 0px 5px @lightBlue;
    }
    #ccv {
        position: absolute;
        left: 50%;
        top: calc(50% - 5px);
        width: 1px;
        height: 11px;
        background: @lightBlue;
        box-shadow: 0px 0px 5px @lightBlue;
    }

    #leftArrow, #rightArrow {
        position: absolute;
        top: 50%;
        width: 70px;
        height: 1px;
        cursor: pointer;
        transform: rotate(180deg);

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 70px;
            height: 1px;
            background: rgba(@lightBlue, 60%);
        }
    }

    #leftArrow {
        left: 40%;
        &:before {
            transform:
                translateY(25px)
                rotate(45deg);
        }
        &:after {
            transform:
                translateY(-25px)
                rotate(-45deg);
        }
    }

    #rightArrow {
        left: calc(60% - 70px);
        &:before {
            transform:
                translateY(25px)
                rotate(-45deg);
        }
        &:after {
            transform:
                translateY(-25px)
                rotate(45deg);
        }
    }

    #redLeftArrow, #redRightArrow {
        position: absolute;
        top: 50%;
        width: 10px;
        height: 1px;
        cursor: pointer;
        transform: rotate(180deg);
        transition: transform .2s ease-out;

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 1px;
            background: rgba(@red, 100%);
        }
    }

    #redLeftArrow {
        left: calc(40% + 35px);
        &:before {
            transform:
                translateY(4px)
                rotate(45deg);
        }
        &:after {
            transform:
                translateY(-4px)
                rotate(-45deg);
        }
    }

    #redRightArrow {
        left: calc(60% - 44px);
        &:before {
            transform:
                translateY(4px)
                rotate(-45deg);
        }
        &:after {
            transform:
                translateY(-4px)
                rotate(45deg);
        }
    }

    &.focusedSat {
        #redLeftArrow {
            transform: rotate(180deg) translate(-15px);
        }
        #redRightArrow {
            transform: rotate(180deg) translate(15px);
        }
    }

    #topArrow, #bottomArrow {
        position: absolute;
        left: calc(50% - 19px);
        width: 20px;
        height: 1px;
        cursor: pointer;
        transform: rotate(180deg);

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 20px;
            background: @lightBlue;
            box-shadow: 0px 0px 5px @lightBlue;
        }
    }

    #topArrow {
        top: 40%;
        &:before {
            transform:
                translateX(-7px)
                rotate(45deg);
        }
        &:after {
            transform:
                translateX(7px)
                rotate(-45deg);
        }
    }

    #bottomArrow {
        top: calc(60% + 20px);
        &:before {
            transform:
                translateX(-7px)
                rotate(-45deg);
        }
        &:after {
            transform:
                translateX(7px)
                rotate(45deg);
        }
    }

    #gh-left, #gh-right {
        position: absolute;
        top: 50%;
        width: 100px;
        height: 1px;
        background: @lightBlue;
        box-shadow: 0px 0px 5px @lightBlue;
    }
    #gh-left {
        left: calc(25% - 50px);
    }
    #gh-right {
        left: calc(75% - 50px);
    }
}

@media screen and (max-width:600px) {
    #globalCross {
        #gh-left {
            width: 50px;
            left: 50px;
        }
        #gh-right {
            width: 50px;
            left: calc(100% - 100px);
        }

        #leftArrow {
            left: calc(50% - 100px);
        }
        #rightArrow {
            left: calc(50% + 30px);
        }
        #redLeftArrow {
            left: calc(50% - 55px);
        }
        #redRightArrow {
            left: calc(50% + 45px);
        }
    }
}

