input[type="text"] {
    width: 100%;
    background: rgba(11, 39, 52, 0.8);
    font-size: 16px;
    padding: 10px 0;
    border-bottom: 1px solid @green;
}

textarea {
    background: rgba(11, 39, 52, 0.8);
}

label.radio, label.checkbox {
    cursor: pointer;
    position: relative;
    display: inline-block;
    div {
        border: 1px solid rgba(@blue, 0.4);
        line-height: 38px;
        height: 40px;
        color: rgba(@blue, 0.6);
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 5px;
            background: @blue;
        }
        &:after {
            content: "";
            position: absolute;
            height: 1px;
            background: rgba(@blue, 0.6);
        }
        &:hover {
            /*background: rgba(@lightBlue, 0.1);*/
            color: @lightBlue;
            border-color: @lightBlue;
        }
    }

    input[type="radio"], input[type="checkbox"] {
        display: none;
    }
}

label.radio input:checked, label.checkbox input:checked {
    ~ div {
        border-color: @green;
        color: @lightBlue;
        opacity: 1;

        &:before {
            background: @lightBlue;
        }
        &:after {
            width: 0 !important;
        }
        &:hover {
            background: none;
        }
    }
}
